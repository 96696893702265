/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrainingRequestStatusData } from "../models/TrainingRequestStatusData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingRequestStatusService {
  /**
   * List of Training Request Statis
   * Return training request status data
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingRequestStatus(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestStatusData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-request-status`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * List of Filter Training Request Statis
   * Return filtered training request status data
   * @returns any successful operation
   * @throws ApiError
   */
  public static getFilterTrainingRequestStatus(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestStatusData;
  }> {
    return __request({
      method: "GET",
      path: `/api/filter-training-request-status`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }
}
