/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserFeedbackData } from "../models/UserFeedbackData";
import type { UserFeedbackPaginationResponseDto } from "../models/UserFeedbackPaginationResponseDto";
import type { StoreUserFeedbackRequest } from "../models/StoreUserFeedbackRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class UserFeedbackService {
  /**
   * Get list of user feedback
   * Returns list of user feedback with pagination
   * @param name Search By Name
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param order Order (By default asc)
   * @param trainingTopicId Training Topic Id
   * @param fromDate From Date
   * @param toDate To Date
   * @returns any successful operation
   * @throws ApiError
   */
  public static getuserFeedbackList(
    name?: string,
    page?: number,
    limit?: number,
    order?: string,
    trainingTopicId?: string,
    fromDate?: string,
    toDate?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserFeedbackPaginationResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/user-feedback`,
      query: {
        name: name,
        page: page,
        limit: limit,
        order: order,
        trainingTopicId: trainingTopicId,
        fromDate: fromDate,
        toDate: toDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store User Feedback Score
   * Store user feedback & return user feedback data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeUserFeedback(
    requestBody?: StoreUserFeedbackRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserFeedbackData;
  }> {
    return __request({
      method: "POST",
      path: `/api/user-feedback`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get User Feedback Information
   * Get User Feedback Information
   * @param eventId Event ID
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUserFeedbackByEventId(eventId: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserFeedbackData;
  }> {
    return __request({
      method: "GET",
      path: `/api/user-feedback/event/${eventId}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
