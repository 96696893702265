import { FiBell } from "react-icons/fi";
import { useState, useEffect } from "react";
import * as signalR from "@microsoft/signalr";
import {
  selectIsAuthenticated,
  selectUser,
  selectToken,
} from "../../auth/authSlice";
import { useSelector } from "react-redux";
import AuthService from "../../../services/authService";
import { selectUserRole } from "../../auth/userRoleSlice";
import { Dropdown, Spinner } from "react-bootstrap";
import useTmsCoreApi from "../../../hooks/useTmsCoreApi";
import { NotificationService } from "../../../services/tms-core-services";
import { isProxy } from "../../auth/proxyUserSlice";
import { useHistory } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import styled from "styled-components";

function Notification() {
  const user: any = useSelector(selectUser);
  const authService = new AuthService();
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const userRole: any = useSelector(selectUserRole);
  const token = useSelector(selectToken);
  const proxy = useSelector(isProxy);

  const [unreadNotificationCount, setUnreadNotificationCount] = useState(0);
  const [notificationData, setNotificationData] = useState<any>([]);
  const [metaData, setMetaData] = useState({
    current_page: 1,
    perPage: 10,
    total: 0,
  });
  const [signalRStatus, setSignalRStatus] = useState<string | null>("");
  const [hasMoreNotification, setHasMoreNotification] = useState(true);
  const [page, setPage] = useState(2);
  const history = useHistory();

  // OUR CUSTOM HOOK TO WORK WITH API
  const { handleRequest, dismissError, error, isLoading } = useTmsCoreApi();

  // INTEGRATING SIGNAL R
  useEffect(() => {
    const connection = new signalR.HubConnectionBuilder()
      .withUrl(
        `${window.__RUNTIME_CONFIG__.REACT_APP_TMS_NOTIFICATION_API_ENDPOINT}/signalr-hubs/bellNotification`,
        {
          transport: signalR.HttpTransportType.LongPolling,
          accessTokenFactory: () => {
            return token || "";
          },
        }
      )
      .configureLogging(signalR.LogLevel.Information)
      .build();
    /*
    connection.on("ReceiveBellNotification", () => {
      // HOW DOES THIS WORK???
      // handleRequest(NotificationService.getUnreadNotificationCount())
      //   .then((response: any) => {
      //     setUnreadNotificationCount(response?.data?.unreadNotification);
      //   })
      //   .catch((e) => {
      //     console.log(e);
      //   });
    });

    
    connection.on("ReceiveBellNotification", (data: any) => {
      console.log(data);
      // setNotificationData([...notificationData, data]);
      // setUnreadNotificationCount(unreadNotificationCount + 1);
    })
    */
    async function start() {
      try {
        await connection.start();
        setSignalRStatus(connection.connectionId);
        console.log("SignalR Connected.");
      } catch (err) {
        setTimeout(start, 5000);
      }
    }
    connection.onclose(start);
    // Start the connection.
    start();
  }, []);

  //  GET NOTIFICATION LIST
  useEffect(() => {
    unreadNotifications();
    getNotificationList();
  }, [proxy, signalRStatus]);

  const getNotificationList = () => {
    const { current_page, perPage, total } = metaData;
    // console.log(page);
    // console.log(total);
    if (page === 2) {
      handleRequest(
        NotificationService.getNotificationList(current_page, perPage)
      )
        .then((response: any) => {
          // console.log(response);
          setNotificationData(response?.data?.notification);
          setMetaData({ ...metaData, total: response?.data?.meta?.total });
          if (response?.data?.meta?.total === 0) {
            setHasMoreNotification(false);
          }
        })
        .catch((e) => {
          console.log(e);
        });
    } else {
      handleRequest(
        NotificationService.getNotificationList(current_page, total)
      )
        .then((response: any) => {
          // console.log(response);
          setNotificationData(response?.data?.notification);
          setMetaData({ ...metaData, total: response?.data?.meta?.total });
        })
        .catch((e) => {
          console.log(e);
        });
    }
  };

  // LOAD MORE NOTIFICATION
  const handlePageChange = () => {
    const { current_page, perPage, total } = metaData;
    handleRequest(NotificationService.getNotificationList(page, perPage))
      .then((response: any) => {
        setNotificationData([
          ...notificationData,
          ...response?.data?.notification,
        ]);

        // console.log(notificationData.length);
        // console.log(total);
        if (
          notificationData.length === 0 ||
          notificationData.length < 10 ||
          notificationData.length === total
        ) {
          setHasMoreNotification(false);
        }

        setPage(page + 1);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // GET UNREAD NOTIFICATION
  const unreadNotifications = () => {
    handleRequest(NotificationService.getUnreadNotificationCount())
      .then((response: any) => {
        // console.log(response?.data);
        setUnreadNotificationCount(response?.data?.unreadNotification);
      })
      .catch((e) => console.log(e));
  };

  // SEEN NOTIFICATION
  const handleSeen = (
    id: string,
    pageName: string = "home",
    feedbackId: string
  ) => {
    if (id) {
      handleRequest(NotificationService.markNotificationAsRead(id))
        .then((response: any) => {
          if (response) {
            switch (pageName) {
              case "home":
                history.push("/home");
                break;
              case "tnas":
                history.push("/tnas");
                break;
              case "events":
                history.push("/events");
                break;
              case "feedback":
                history.push("/feedback");
                break;
              case "feedback-form":
                history.push(`/feedback-form/${feedbackId}`);
                break;
              default:
                history.push("/home");
                break;
            }
          }
          unreadNotifications();
        })
        .catch((e) => {
          console.log(e);
        });
    } else return;
  };

  // RENDERING ALL THE NOTIFICATION IN LIST
  const renderNotificationList = () => {
    return notificationData.map((item: any) => {
      try {
        const noticeUrl = JSON.parse(item.description).Message?.Url;
        // console.log(noticeUrl);
        const [routeKeyword] = noticeUrl?.split("/").slice(3);
        // const routeKeyword = noticeUrl?.split("/").at(-1);
        const [feedbackId] = noticeUrl?.split("/").slice(-1);
        //console.log(feedbackId);
        //console.log(routeKeyword);
        return (
          <div key={item.id}>
            <Dropdown.Item
              className={item.isSeen ? " pe-4" : "unread pe-4"}
              onClick={() => handleSeen(item.id, routeKeyword, feedbackId)}
            >
              <h6 className="text-wrap small"> {item.title}</h6>
              <p className="m-0 text-wrap small">
                {JSON.parse(item.description).Message.Text}
              </p>
            </Dropdown.Item>
          </div>
        );
      } catch (e) {
        console.log(e);
      }
    });
  };

  return (
    <div className="notification__wrapper">
      <div className="notification">
        <Dropdown autoClose={true}>
          <Dropdown.Toggle
            id="user-details"
            variant="transparent"
            className="d-flex align-items-center p-0"
          >
            <FiBell
              className="notification__icon"
              onClick={getNotificationList}
            />
            {unreadNotificationCount > 0 ? (
              <span className="notification__count text-white text-center">
                {unreadNotificationCount}
              </span>
            ) : (
              ""
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            {((isAuthenticated && userRole.role === "Super Admin") ||
              userRole.role === "Employee" ||
              userRole.role === "CXO" ||
              userRole.role == "LM" ||
              userRole.role === "LMM") && (
              <>
                <h6 className="text-center my-2">Notifications</h6>
                <Dropdown.Divider />
                <div
                  className="notification__list custom-scrollbar"
                  id="notificationListScroll"
                >
                  {notificationData && (
                    <InfiniteScroll
                      dataLength={notificationData.length} //This is important field to render the next data
                      next={handlePageChange}
                      hasMore={hasMoreNotification}
                      loader={
                        <SpinnerContainer>
                          <Spinner
                            animation="grow"
                            variant="danger"
                            size="sm"
                          />
                          <Spinner
                            animation="grow"
                            variant="danger"
                            size="sm"
                            style={{ margin: "0 7px" }}
                          />
                          <Spinner
                            animation="grow"
                            variant="danger"
                            size="sm"
                          />
                        </SpinnerContainer>
                      }
                      endMessage={
                        <div className="text-center my-2 text-danger small">
                          {notificationData.length === 0 ? (
                            <p>No notification !</p>
                          ) : (
                            <p>No more notification !</p>
                          )}
                        </div>
                      }
                      scrollableTarget="notificationListScroll"
                    >
                      {renderNotificationList()}
                    </InfiniteScroll>
                  )}
                </div>
              </>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </div>
  );
}

export default Notification;

const SpinnerContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
`;
