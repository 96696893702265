/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrainingTypeCollectionResponseDto } from "../models/TrainingTypeCollectionResponseDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingTypeService {
  /**
   * Get list of training type
   * Returns list of training type
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingTypeList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTypeCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-type`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
