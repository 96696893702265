import { useCallback, useState } from "react";
import { ApiError, OpenAPI } from "../services/tms-core-services";
import { selectToken } from "../pages/auth/authSlice";
import { useSelector } from "react-redux";
import { isProxy, proxyUserId } from "../pages/auth/proxyUserSlice";
import axios from "axios";

export function useTmsCoreApi() {
  const [error, setError] = useState<ApiError | undefined>(undefined);
  const [isLoading, setIsloading] = useState<boolean>(false);
  const token = useSelector(selectToken);
  const proxyUser = useSelector(proxyUserId);
  const proxy = useSelector(isProxy);
  const proxyuserIds = localStorage.getItem("proxyUserId");

  OpenAPI.BASE = window.__RUNTIME_CONFIG__.REACT_APP_TMS_CORE_API_ENDPOINT;
  OpenAPI.TOKEN = token?.toString();

  if (proxy && proxyUser) {
    OpenAPI.HEADERS = {
      ProxyUserId: proxyUser,
      HasProxyUser: proxy.toString(),
    };
  } else if (proxyuserIds) {
    OpenAPI.HEADERS = { ProxyUserId: proxyuserIds, HasProxyUser: "true" };
  } else {
    OpenAPI.HEADERS = {};
  }

  const handleRequest = useCallback(async function <T>(request: Promise<T>) {
    setIsloading(true);
    try {
      const response = await request;
      setError(undefined);
      return response;
    } catch (exception: any) {
      setError(exception);
    } finally {
      setIsloading(false);
    }
  }, []);

  function dismissError() {
    setError(undefined);
  }

  return { dismissError, error, isLoading, handleRequest };
}

export default useTmsCoreApi;
