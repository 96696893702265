import React from "react";
import PropTypes from "prop-types";
import { default as ReactSelect } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { isJsxFragment } from "typescript";

const MySelect = (props: any) => {
    if (props.allowSelectAll) {
        return (
            <ReactSelect
                {...props}
                options={[props.allOption, ...props.options]}
                onChange={(selected: any) => {
                    if (
                        selected !== null &&
                        selected.length > 0 &&
                        selected[selected.length - 1].value === props.allOption.value
                    ) {
                        return props.onChange(props.options);
                    }
                    return props.onChange(selected);
                }}
            />
        );
    } else if (props.isCreatable) {
        return (
            <CreatableSelect
                {...props}
                options={[...props?.options]}
                isMulti
            />
        )
    }

    return <ReactSelect {...props} />;
};

MySelect.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func,
    allowSelectAll: PropTypes.bool,
    allOption: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
    }),
    placeholder: PropTypes.any,
    isMulti: PropTypes.any,
    isSearchable: PropTypes.any,
    closeMenuOnSelect: PropTypes.any,
    hideSelectedOptions: PropTypes.any,
    components: PropTypes.any,
    hasPills: PropTypes.any,
    className: PropTypes.any,
    classNamePrefix: PropTypes.any,
    isCreatable: PropTypes.bool,
    hasAdd: PropTypes.bool,
    othersModal: PropTypes.func,
    viewOthersModal: PropTypes.bool,
    isDisabled: PropTypes.bool,
    hasTooltip: PropTypes.bool
};

MySelect.defaultProps = {
    allOption: {
        label: "Select all",
        value: "*",
    },
};

export default MySelect;
