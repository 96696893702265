/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveBulkTrainingRequest } from "../models/ApproveBulkTrainingRequest";
import type { ApproveTrainingRequest } from "../models/ApproveTrainingRequest";
import type { TrainingRequestCollectionData } from "../models/TrainingRequestCollectionData";
import type { TrainingRequestData } from "../models/TrainingRequestData";
import type { TrainingRequestPaginationData } from "../models/TrainingRequestPaginationData";
import type { TrainingRequestYearCollectionData } from "../models/TrainingRequestYearCollectionData";
import type { RejectBulkTrainingRequest } from "../models/RejectBulkTrainingRequest";
import type { RejectTrainingRequest } from "../models/RejectTrainingRequest";
import type { StoreTrainingRequest } from "../models/StoreTrainingRequest";
import type { UpdateTrainingRequest } from "../models/UpdateTrainingRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingRequestService {
  /**
   * Get list of year for which training request is available
   * Returns list of yearr
   * @returns any successful operation
   * @throws ApiError
   */
  public static getAvailableRequestYears(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestYearCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-request-years`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * View training request information
   * Return training request data
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param orderBy Order By
   * @param trainingTypeId Training Type Id
   * @param trainingTopicId Training Topic Id
   * @param year Year
   * @param department Department Id
   * @param employee Employee
   * @param statusId Status
   * @returns any successful operation
   * @throws ApiError
   */
  public static viewTrainingRequest(
    page?: number,
    limit?: number,
    orderBy?: string,
    trainingTypeId?: string,
    trainingTopicId?: string,
    year?: string,
    department?: string,
    employee?: string,
    statusId?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestPaginationData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-request`,
      query: {
        page: page,
        limit: limit,
        orderBy: orderBy,
        trainingTypeId: trainingTypeId,
        trainingTopicId: trainingTopicId,
        year: year,
        department: department,
        employee: employee,
        statusId: statusId,
      },
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store training request information
   * Store training request & return training request data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeTrainingRequest(
    requestBody?: StoreTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestData;
  }> {
    return __request({
      method: "POST",
      path: `/api/training-request`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get training request information
   * Get training request information
   * @param id Training Request Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingRequestById(id: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-request/${id}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update training request information
   * Update training request & return training request data
   * @param id Training Request Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateTrainingRequest(
    id: string,
    requestBody?: UpdateTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/training-request/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Approve training request
   * Approve training request & return training request data
   * @param id Training Request Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static approveTrainingRequest(
    id: string,
    requestBody?: ApproveTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/approve-training-request/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Reject training request
   * Reject training request & return training request data
   * @param id Training Request Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static rejectTrainingRequest(
    id: string,
    requestBody?: RejectTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/reject-training-request/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Approve bulk training request
   * Approve bulk training request & return training request data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static approveBulkTrainingRequest(
    requestBody?: ApproveBulkTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestCollectionData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/approve-bulk-training-request`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Reject bulk training request
   * Reject bulk training request & return training request data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static rejectBulkTrainingRequest(
    requestBody?: RejectBulkTrainingRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingRequestCollectionData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/reject-bulk-training-request`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }
}
