import React, { useEffect, useState } from "react";
import { components, ContainerProps } from "react-select";
import MySelect from "./MySelect";
import { FiInfo, FiPlus, FiX } from "react-icons/fi";
import { OverlayTrigger, Tooltip, Button, Popover } from "react-bootstrap";

const Option = (props: any) => {
    const value = props.value;
    const content = props.options.find((data: any) => data.value === value);
    return (
        <div>
            <components.Option {...props}>
                <input
                    type="checkbox"
                    checked={props.isSelected}
                    onChange={() => null}
                    className="css-rselect_radio"
                />{" "}
                <span>{props?.data?.label}</span>
                {
                    props.selectProps.hasTooltip &&
                    <OverlayTrigger
                        key="right"
                        placement="right"
                        overlay={
                            <Popover id="test" className="popoverOnHover p-3">
                                <Popover.Header as="h3" className="bg-white pb-3 pt-0 px-0 d-flex justify-content-between align-items-center">
                                    <span>{content.content.name}</span>
                                </Popover.Header>
                                <Popover.Body className="p-0">
                                    <div className="py-3">
                                        {
                                            content.content.trainingDescription &&
                                            <>
                                                <p className="mb-2 details__section__title"><b>Training Description</b></p>
                                                <div className="" dangerouslySetInnerHTML={{ __html: content.content.trainingDescription }} />
                                            </>
                                        }
                                        {
                                            content.content.targetAudienceDescription &&
                                            <>
                                                <p className="mb-2 details__section__title"><b>Target Audience Description</b></p>
                                                <div className="mb-4" dangerouslySetInnerHTML={{ __html: content.content.targetAudienceDescription }} />
                                            </>
                                        }
                                        <p className="mb-2 details__section__title">Training Skillsets</p>
                                        <div className="skillSets_section">
                                            {
                                                content.content.skills.length &&
                                                content.content.skills.map((skill: any) => {
                                                    return <span className="badge rounded-pill bg-secondary px-3 py-2 p-2 mb-2 mr-2">{skill.name}</span>
                                                })
                                            }
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between pt-2">
                                        <div>
                                            <p className="text-light mb-2">Type</p>
                                            <span>{content.content.trainingType.name}</span>
                                        </div>
                                        <div>
                                            <p className="text-light mb-2">Level</p>
                                            <span>{content.content.trainingLevel.name}</span>
                                        </div>
                                    </div>
                                </Popover.Body>
                            </Popover>
                        }
                    >
                        <span style={{'textAlign' : 'right'}}><FiInfo /></span>
                    </OverlayTrigger>
                }
            </components.Option>
        </div>
    );
};

const MultiValue = (props: any) => (
    <components.MultiValue {...props}>
        <span>{props?.data?.label}</span>
    </components.MultiValue>
);

const AddItemSection = (props: any) => {
    const handleClick = () => {
        props.selectProps.othersModal(!props.selectProps.viewOthersModal);
    }
    return (
        <components.MenuList  {...props}>
            <div className="css-rselect__menu-list_innerContainer">
                {props.children}
            </div>
            {props.selectProps.hasAdd &&
                <>
                    <div className="select__addNew">
                        <div className="border-top mx-3" onClick={handleClick}>
                            <p className="mb-2 pt-2 select__addNew_text"><FiPlus /> Others</p>
                            <span className="text-secondary d-block pb-2">(Add new topic)</span>
                        </div>
                    </div>
                </>}
        </components.MenuList >
    )
}

export const DropdownSelect = (props: any) => {
    return (
        <div>
            <MySelect
                options={props.options}
                placeholder={props.placeholder}
                isMulti={props.isMulti}
                isSearchable={props.isSearchable}
                closeMenuOnSelect={props.closeMenu}
                hideSelectedOptions={false}
                components={{
                    Option,
                    MultiValue,
                    MenuList: AddItemSection,
                }}
                onChange={props.handleChange}
                allowSelectAll={props.allowSelectAll}
                isCreatable={props.isCreatable}
                hasPills={props.hasPills}
                value={props?.value}
                hasAdd={props.hasAdd}
                othersModal={props.othersModal}
                viewOthersModal={props.viewOthersModal}
                isDisabled={props.disabled}
                hasTooltip={props.hasTooltip}
                className={`tms__react__select ${props.hasPills && "select_pills"} ${props.hasAdd && "select_hasAdd"}`}
                classNamePrefix="css-rselect"
            />
        </div>
    );
};
