/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UserListCollectionResponseData } from "../models/UserListCollectionResponseData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class UserService {
  /**
   * Get list of proxy users
   * Returns list of proxy users
   * @returns any successful operation
   * @throws ApiError
   */
  public static getProxyUserList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserListCollectionResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/get-proxy-user`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get list of users
   * Returns list of users
   * @param name Search By Name
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUserList(name?: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserListCollectionResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/get-user`,
      query: {
        name: name,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
