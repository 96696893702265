import React, { Fragment } from "react";
import spinner from "../assets/images/Spinner.gif";
import "../assets/css/loader.scss";

const Spinner = () => (
  <Fragment>
    <div className="spinner">
      <div className="spinner__container">
        <img
          src={spinner}
          alt="Loading..."
          className="spinner__container__image"/>
      </div>
    </div>
  </Fragment>
);

export default Spinner;
