export const IDENTITY_CONFIG = {
  authority: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL, //(string): The URL of the OIDC provider.
  client_id: window.__RUNTIME_CONFIG__.REACT_APP_IDENTITY_CLIENT_ID, //(string): Your client application's identifier as registered with the OIDC provider.
  redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_REDIRECT_URL, //The URI of your client application to receive a response from the OIDC provider.
  login: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/login",
  automaticSilentRenew: false, //(boolean, default: false): Flag to indicate if there should be an automatic attempt to renew the access token prior to its expiration.
  loadUserInfo: false, //(boolean, default: true): Flag to control if additional identity data is loaded from the user info endpoint in order to populate the user's profile.
  silent_redirect_uri: window.__RUNTIME_CONFIG__.REACT_APP_SILENT_REDIRECT_URL, //(string): The URL for the page containing the code handling the silent renew.
  post_logout_redirect_uri:
    window.__RUNTIME_CONFIG__.REACT_APP_LOGOFF_REDIRECT_URL, // (string): The OIDC post-logout redirect URI.
  audience: "TrainingManagementSystemResource", //is there a way to specific the audience when making the jwt
  response_type: "code", //(string, default: 'id_token'): The type of response desired from the OIDC provider.
  grantType: "code",
  scope: "openid profile TrainingManagementSystemResource email role", //(string, default: 'openid'): The scope being requested from the OIDC provider.
  webAuthResponseType: "code",
  monitorSession: false,
  client_secret: "1q2w3e*",
};

export const METADATA_OIDC = {
  issuer: window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL,
  jwks_uri:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL +
    "/.well-known/openid-configuration/jwks",
  authorization_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/authorize",
  token_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/token",
  userinfo_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/userinfo",
  end_session_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/endsession",
  check_session_iframe:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/checksession",
  revocation_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/revocation",
  introspection_endpoint:
    window.__RUNTIME_CONFIG__.REACT_APP_AUTH_URL + "/connect/introspect",
  client_secret: "1q2w3e*",
};
