/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AuthUserData } from "../models/AuthUserData";
import type { DashboardData } from "../models/DashboardData";
import type { LoggedInDetailData } from "../models/LoggedInDetailData";
import type { UserPermissionData } from "../models/UserPermissionData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class MeService {
  /**
   * Get auth user data
   * Returns data of auth user
   * @returns any successful operation
   * @throws ApiError
   */
  public static getAuthUserData(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: AuthUserData;
  }> {
    return __request({
      method: "GET",
      path: `/api/me`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get user data
   * Returns data of user
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUserDetailById(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: AuthUserData;
  }> {
    return __request({
      method: "GET",
      path: `/api/me/user-detail-by-id`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get auth user's permission list
   * Returns data of auth user's permission list
   * @returns any successful operation
   * @throws ApiError
   */
  public static getAuthUserPermission(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserPermissionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/me/permission`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get dashboard detail
   * Returns dashboard detail
   * @returns any successful operation
   * @throws ApiError
   */
  public static getDashboardDetail(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: DashboardData;
  }> {
    return __request({
      method: "GET",
      path: `/api/me/dashboard`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store logged in details information
   * Store logged in details & return logged in details data
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeLoggedInTime(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: LoggedInDetailData;
  }> {
    return __request({
      method: "POST",
      path: `/api/save-logged-in-time`,
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get logged in details information
   * Get logged in details & return logged in details data
   * @returns any successful operation
   * @throws ApiError
   */
  public static getLoggedInTime(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: LoggedInDetailData;
  }> {
    return __request({
      method: "GET",
      path: `/api/get-logged-in-time`,
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
