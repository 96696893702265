import { useEffect } from 'react';
import AuthService from "../../services/authService";
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setCredentials } from './authSlice'
import useTmsCoreApi from '../../hooks/useTmsCoreApi';
import { MeService } from '../../services/tms-core-services';
import { setUserRole } from './userRoleSlice';

const Callback = (prop: any) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const authService = new AuthService();

    const { dismissError, error, isLoading, handleRequest } = useTmsCoreApi();

    useEffect(() => {
        if (!authService.isAuthenticated()) {
            authService.signinRedirectCallback().then((user) => {
                dispatch(setCredentials({ isAuthenticated: authService.isAuthenticated(), token: authService.getToken(), user }));

                const getAuthUserDataPromise = handleRequest(MeService.getAuthUserData());
                const getAuthUserPermissionPromise = handleRequest(MeService.getAuthUserPermission());

                Promise.all([getAuthUserDataPromise, getAuthUserPermissionPromise]).then((values: any) => {
                    dispatch(setUserRole({ userRole: values[0].data, permission: values[1].data.permission }));
                    history.push("/home");
                }).catch(exception => {
                    history.push('/login');
                    authService.signoutRedirectCallback();
                    console.log(exception);
                });
            });
        } else {
            (async () => {
                dispatch(setCredentials({ isAuthenticated: authService.isAuthenticated(), token: authService.getToken(), user: {} }));

                const getAuthUserDataPromise = handleRequest(MeService.getAuthUserData());
                const getAuthUserPermissionPromise = handleRequest(MeService.getAuthUserPermission());

                Promise.all([getAuthUserDataPromise, getAuthUserPermissionPromise]).then((values: any) => {
                    dispatch(setUserRole({ userRole: values[0].data, permission: values[1].data.permission }));
                    history.push("/home");
                }).catch(exception => {
                    history.push('/login');
                    authService.signoutRedirectCallback();
                    console.log(exception);
                });
            })();
        }
    }, []);
    return (
        <div>
            <h1>Logging You In</h1>
        </div>
    );
}

export default Callback;