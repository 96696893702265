/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FeedbackQuestionCategoryData } from "../models/FeedbackQuestionCategoryData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class FeedbackQuestionService {
  /**
   * Get list of feedback question
   * Returns list of feedback Question
   * @param eventId Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getFeedbackQuestion(eventId: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: FeedbackQuestionCategoryData;
  }> {
    return __request({
      method: "GET",
      path: `/api/feedback-question/${eventId}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
