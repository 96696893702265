import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

type events = {
    search: string,
    from: Date | null,
    to: Date | null,
    statusId: string | null,
    statusValue: string | null
}

const event = createSlice({
    name: 'trainingEventsParams',
    initialState: { search: '', from: null, to: null, statusId: '', statusValue: '' } as events,
    reducers: {
        setTrainingEvent: (
            state,
            { payload: { search, from, to, statusId, statusValue } }: PayloadAction<{ search: string, from: Date | null, to: Date | null, statusId: string, statusValue: string }>
        ) => {
            state.search = search
            state.from = from
            state.to = to
            state.statusId = statusId
            state.statusValue = statusValue
        },
    },
});


export const { setTrainingEvent } = event.actions;

export default event.reducer

export const trainingEvent = (state: RootState) => state?.event;