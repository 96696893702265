import { Suspense } from 'react';
import {
    Switch,
    Route
} from "react-router-dom";
import PrivateRoute from './PrivateRoute';
import ProtectedRoutes from './ProtectedRoutes';
import Login from '../pages/auth/Login';
import Callback from '../pages/auth/Callback';
import { selectIsAuthenticated } from '../pages/auth/authSlice';
import { useSelector } from 'react-redux';
import AuthService from '../services/authService';
import { selectPermissions } from '../pages/auth/userRoleSlice';

export const Routes = () => {
    const authService = new AuthService();
    const isAuthenticated = useSelector(selectIsAuthenticated) || authService.isAuthenticated();

    const permissionLists = useSelector(selectPermissions);
    return (
        <Suspense fallback={() => (<>LOADING</>)}>
            <Switch>
                <Route path='/login' component={Login} />
                <Route path='/callback' component={Callback} />
                <PrivateRoute
                    path="/"
                    isAuthenticated={isAuthenticated}>
                    <ProtectedRoutes permissionLists={permissionLists} />
                </PrivateRoute>
            </Switch>
        </Suspense>
    );
}