import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

type UserState = {
    userRole: any,
    permission: any
}

const userRole = createSlice({
    name: 'userRole',
    initialState: { userRole: Object, permission: Object } as UserState,
    reducers: {
        setUserRole: (
            state,
            { payload: { userRole, permission } }: PayloadAction<{ userRole: object, permission: object }>
        ) => {
            state.userRole = userRole
            state.permission = permission
        },
    },
})


export const { setUserRole } = userRole.actions;

export default userRole.reducer

export const selectUserRole = (state: RootState) => state?.userRole?.userRole

export const selectPermissions = (state: RootState) => state?.userRole?.permission



