import * as Fi from "react-icons/fi";

export const sideBarData = [
  {
    menu_id: "view_home",
    menu_icon: <Fi.FiHome />,
    menu_title: "Home",
    menu_url: "/",
  },
  {
    menu_id: "view_topics",
    menu_icon: <Fi.FiList />,
    menu_title: "Topics",
    menu_url: "/topics",
  },
  {
    menu_id: "my_tna",
    menu_icon: <Fi.FiTv />,
    menu_title: "TNAs",
    menu_url: "/tnas",
  },
  {
    menu_id: "view_events",
    menu_icon: <Fi.FiCalendar />,
    menu_title: "Events",
    menu_url: "/events",
  },
  {
    menu_id: "view_feedback",
    menu_icon: <Fi.FiSmile />,
    menu_title: "Feedbacks",
    menu_url: "/feedback",
  },
  {
    menu_id: "view_budget",
    menu_icon: <Fi.FiBriefcase />,
    menu_title: "Budget",
    menu_url: "/budgets",
  },
  {
    menu_id: "view_reports",
    menu_icon: <Fi.FiBarChart2 />,
    menu_title: "Reports",
    menu_url: "/reports",
  },
  {
    menu_id: "view_users",
    menu_icon: <Fi.FiUser />,
    menu_title: "Users",
    menu_url: "/users",
  },
  {
    menu_id: "view_suggestion",
    menu_icon: <Fi.FiMessageSquare />,
    menu_title: "Suggestion",
    menu_url: "/suggestion",
  },
  {
    menu_id: "view_e_learning_file",
    menu_icon: <Fi.FiBookOpen />,
    menu_title: "eLearning",
    menu_url: "/eLearning",
  },
];

export const statCardData = [
  {
    stat_id: 1,
    stat_icon: <Fi.FiList />,
    stat_value: 38,
    stat_title: "Total Topics",
  },
  {
    stat_id: 2,
    stat_icon: <Fi.FiCalendar />,
    stat_value: 51,
    stat_title: "Total Events",
  },
  {
    stat_id: 3,
    stat_icon: <Fi.FiTv />,
    stat_value: 4353,
    stat_title: "Total Requests",
  },
  {
    stat_id: 4,
    stat_icon: <Fi.FiMeh />,
    stat_value: 2451,
    stat_title: "Total Feedbacks",
  },
];

export const topEventRequest = [
  {
    id: 1,
    title: "Selenium automation",
    fromDate: "19/07/2021",
    toDate: "22/07/2021",
    level: "Intermediate",
    status: "Completed",
    attendeesNo: 224,
  },
  {
    id: 2,
    title: "English language training",
    fromDate: "19/07/2021",
    toDate: "22/07/2021",
    level: "Intermediate",
    status: "Completed",
    attendeesNo: 224,
  },
  {
    id: 3,
    title: "Customer Care",
    fromDate: "19/07/2021",
    toDate: "22/07/2021",
    level: "Intermediate",
    status: "In Progress",
    attendeesNo: 224,
  },
  {
    id: 4,
    title: "Certified motivation training",
    fromDate: "19/07/2021",
    toDate: "22/07/2021",
    level: "Intermediate",
    status: "In Progress",
    attendeesNo: 224,
  },
];
export const latestTrainingRequest = [
  {
    id: 1,
    name: "Ram Hari Mohan",
    date: "19/07/2021",
    post: "Employee",
    department: "IT Department",
    training: "Time Management",
  },
  {
    id: 2,
    name: "Ram Shyam Mohan",
    date: "19/07/2021",
    post: "LM",
    department: "HR Department",
    training: "Business Goal",
  },
  {
    id: 3,
    name: "Bal Mohan",
    date: "19/07/2021",
    post: "Manager",
    department: "Marketing Dept",
    training: "Selenium",
  },
  {
    id: 4,
    name: "Radhika Sharma",
    date: "19/07/2021",
    post: "Employee",
    department: "IT Dept.",
    training: "Training",
  },
];
export const upComingEvents = [
  {
    id: 1,
    eventName: "Selenium automation",
    eventDate: "19/07/2021",
    startTime: "12:00 PM",
    endTime: "3:00 PM",
    location: "Lazimpat, Kathmanu",
  },
  {
    id: 2,
    eventName: "Selenium automation",
    eventDate: "19/07/2021",
    startTime: "12:00 PM",
    endTime: "3:00 PM",
    location: "Lazimpat, Kathmanu",
  },
];

export const feedbackReport = [
  {
    id: 1,
    fdValue: 816,
    fdTitle: "Strongly Agree",
  },
  {
    id: 1,
    fdValue: 656,
    fdTitle: "Agree",
  },
  {
    id: 1,
    fdValue: 723,
    fdTitle: "Nither Agree Nor Disagree",
  },
  {
    id: 1,
    fdValue: 608,
    fdTitle: "Disagree",
  },
  {
    id: 1,
    fdValue: 514,
    fdTitle: "Strongly Disagree",
  },
];

export const chartData = [
  {
    id: 1,
    label: "Company",
    data: 10000,
  },
  {
    id: 2,
    label: "Allocated",
    data: 3000,
  },
  {
    id: 3,
    label: "Remaining",
    data: 7500,
  },
  {
    id: 4,
    label: "Utilized",
    data: 5000,
  },
];
