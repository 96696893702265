/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RoleCollectionResponseDto } from "../models/RoleCollectionResponseDto";
import type { RoleData } from "../models/RoleData";
import type { RolePaginationResponseDto } from "../models/RolePaginationResponseDto";
import type { StoreRoleRequest } from "../models/StoreRoleRequest";
import type { UpdateRoleRequest } from "../models/UpdateRoleRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class RoleService {
  /**
   * Get list of roles
   * Returns list of roles
   * @param name Search By Name
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param orderBy Order By
   * @param order Order (By default asc)
   * @returns any successful operation
   * @throws ApiError
   */
  public static getRolePaginationList(
    name?: string,
    page?: number,
    limit?: number,
    orderBy?: string,
    order?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: RolePaginationResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/role`,
      query: {
        name: name,
        page: page,
        limit: limit,
        orderBy: orderBy,
        order: order,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store role information
   * Store role & return role data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeRole(requestBody?: StoreRoleRequest): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: RoleData;
  }> {
    return __request({
      method: "POST",
      path: `/api/role`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get role information
   * Get Role information
   * @param id Role id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getRoleById(id: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: RoleData;
  }> {
    return __request({
      method: "GET",
      path: `/api/role/${id}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Delete role information
   * Delete Role
   * @param id Role id
   * @returns any successful operation
   * @throws ApiError
   */
  public static deleteRoleById(id: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
  }> {
    return __request({
      method: "DELETE",
      path: `/api/role/${id}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update role information
   * Update role & return role data
   * @param id Role id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateRoleById(
    id: string,
    requestBody?: UpdateRoleRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: RoleData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/role/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of role
   * Get list role information
   * @returns any successful operation
   * @throws ApiError
   */
  public static getRoleList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: RoleCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/role/list`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
