/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TeamCollectionResponseData } from "../models/TeamCollectionResponseData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class SectionService {
  /**
   * Get list Of Section
   * Returns list of Section
   * @param departmentId Search By Department
   * @returns any successful operation
   * @throws ApiError
   */
  public static getSectionList(departmentId?: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TeamCollectionResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/section`,
      query: {
        departmentId: departmentId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
