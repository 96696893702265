import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../pages/auth/authSlice';
import proxyUser from '../pages/auth/proxyUserSlice';
import userReducer from '../pages/auth/userRoleSlice';
import event from '../pages/events/trainingEventSlice';
import feedback from '../pages/feedback/feedbackReportSlice';

export const store = configureStore({
  reducer: {
    auth: authReducer,
    userRole: userReducer,
    proxyUser: proxyUser,
    event: event,
    feedback:feedback,
  }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch