import { useEffect, useState } from "react";
import "../../../assets/css/sidebar.scss";
import { sideBarData } from "../../../constant/index";
import { Link } from "react-router-dom";
import dashboardUser from "../../../assets/images/dashboard-user.png";
import { useSelector } from "react-redux";
import { selectIsAuthenticated, selectUser } from "../../auth/authSlice";
import { selectPermissions, selectUserRole } from "../../auth/userRoleSlice";

export default function Index() {
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const user: any = useSelector(selectUser);
    const userRole: any = useSelector(selectUserRole);
    const permissionLists: any = useSelector(selectPermissions);

    const [img, setImg] = useState(user?.profile?.picture);

    const [scrollPosition, setScrollPosition] = useState(0);
    const handleScroll = () => {
        const position = window.scrollY;
        setScrollPosition(position);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const onError = () => setImg(dashboardUser);

    return (
        <div className="sidebar__container custom-scrollbar">
            {
                isAuthenticated &&
                <div className={`profile__container ${scrollPosition >= 74 ? "mt-4" : ""}`}>
                    <div className="profile__image">
                        <img src={img ? img : dashboardUser} alt="dashboard_user_image" onError={onError} />
                    </div>
                    <span className="profile__name" style={{ 'textAlign': 'center' }}>
                        {
                            userRole?.firstName + ' ' + (userRole?.middleName + ' ' || ' ') + userRole.lastName
                        }
                    </span>
                    <span className="profile__designation">{userRole?.role}</span>
                </div>
            }
            <div className="menu__container">
                {isAuthenticated && sideBarData.map(({ menu_id, menu_icon, menu_title, menu_url }) => {
                    return (
                        Array.isArray(permissionLists) && permissionLists.map((permission: any) => {
                            if (permission.permissionName === menu_id) {
                                return (
                                    <div className="menu__item d-flex align-items-center" key={menu_id}>
                                        {menu_icon}
                                        <Link to={menu_url} className="menu__title">
                                            {menu_title}
                                        </Link>
                                    </div>
                                )
                            }
                        })
                    );
                })}
            </div>
        </div>
    );
}
