import { useEffect, useState } from "react";
import {
  Container,
  Navbar,
  Dropdown,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import "../../../assets/css/header.scss";
import tms_logo from "../../../assets/images/tms_logo.svg";
import default_user_circle from "../../../assets/images/user-circle.svg";
import Search from "../../../components/search";
import Notification from "./Notification";
import { DropdownSelect } from "../../../components/dropdownSelect/DropdownSelect";

import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated, selectUser } from "../../auth/authSlice";
import { selectUserRole, setUserRole } from "../../auth/userRoleSlice";
import { useHistory } from "react-router";
import AuthService from "../../../services/authService";
import useTmsCoreApi from "../../../hooks/useTmsCoreApi";
import { MeService, UserService } from "../../../services/tms-core-services";
import { setProxyUser, isProxy } from "../../auth/proxyUserSlice";

export default function Index(props: any) {
  const history = useHistory();

  const isAuthenticated = useSelector(selectIsAuthenticated);
  const proxy = useSelector(isProxy);
  const authService = new AuthService();
  const dispatch = useDispatch();

  const userRole: any = useSelector(selectUserRole);

  const [getUsersLists, setGetUsersLists] = useState({
    label: "",
    value: "",
  }); //gets users lists from ngage

  const [loggedInTime, setLoggedInTime] = useState("");

  let proxyLocalStroage = false;
  if (localStorage.getItem("proxyUserId")) {
    proxyLocalStroage = true;
  }
  const [selectedUser, setSelectedUser] = useState({
    label: localStorage.getItem("proxyUserName") || "",
    value: localStorage.getItem("proxyUserId") || "",
  }); //set the selected user

  const [show, setShow] = useState(false);
  const [proxyData, setProxyData] = useState(false);

  const [showAdminView, setShowAdminView] = useState(false);

  const user: any = useSelector(selectUser);

  const [img, setImg] = useState(user?.profile?.picture);

  const onError = () => setImg(default_user_circle);

  const { dismissError, error, isLoading, handleRequest } = useTmsCoreApi();

  //get lastLoginTime
  useEffect(() => {
    getLoggedInTime();
  }, [proxy]);

  const getLoggedInTime = () => {
    if (isAuthenticated) {
      handleRequest(MeService.getLoggedInTime())
        .then((data: any) => {
          const lastloggedin = data.data.lastLoggedInTime;
          //console.log(lastloggedin);
          setLoggedInTime(lastloggedin);
        })
        .catch((exception) => {
          console.log(exception);
        });
    }
  };

  useEffect(() => {
    if (show) {
      handleRequest(UserService.getProxyUserList())
        .then((data: any) => {
          const userData = data.data.user.map((item: any) => {
            return {
              value: item.id,
              label: `${item.fullName} (${item.username})`,
            };
          });
          setGetUsersLists(userData);
        })
        .catch((exception) => {
          console.log(exception);
        });
    }
  }, [show]);

  const closeModal = () => {
    setShow(false);
    // setSelectedUser({ label: '', value: '' });
  };

  const handleNewUser = (value: any) => {
    setSelectedUser(value);
    dispatch(
      setProxyUser({
        userId: value.value,
        isProxy: true,
        proxyDetails: {
          firstName: "",
          middleName: "",
          lastName: "",
          phoneNumber: "",
          email: "",
          username: "",
          role: "",
        },
      })
    );
  };

  const cancelUser = () => {
    localStorage.removeItem("proxyUserId");
    localStorage.removeItem("proxyUserName");
    window.location.reload();
  };

  const handleSave = () => {
    const getAuthUserDataPromise = handleRequest(MeService.getAuthUserData());
    const getAuthUserPermissionPromise = handleRequest(
      MeService.getAuthUserPermission()
    );
    const getProxyUserDetailsPromise = handleRequest(
      MeService.getUserDetailById()
    );

    Promise.all([
      getAuthUserDataPromise,
      getAuthUserPermissionPromise,
      getProxyUserDetailsPromise,
    ])
      .then((values: any) => {
        dispatch(
          setUserRole({
            userRole: values[0].data,
            permission: values[1].data.permission,
          })
        );
        dispatch(
          setProxyUser({
            userId: selectedUser.value,
            isProxy: true,
            proxyDetails: values[2].data,
          })
        );
        setProxyData(true);
        localStorage.setItem("proxyUserId", selectedUser.value);
        localStorage.setItem("proxyUserName", selectedUser.label);
      })
      .catch((exception) => {
        console.log(exception);
      });
    setShow(false);
  };

  return (
    <>
      <Navbar variant="light" className="bg-ncell p-0">
        <Container fluid className="p-0">
          <Navbar.Brand className="p-0">
            <img
              alt=""
              src={tms_logo}
              className="d-inline-block align-top pb-1"
              onClick={() => history.push("/home")}
              style={{ cursor: "pointer" }}
            />
          </Navbar.Brand>
          {isAuthenticated && userRole && (
            <>
              <div className="nav__right_section d-flex align-items-center ">
                {loggedInTime && (
                  <span className="text-white small justify-content-center m-2">
                    Last Login : {loggedInTime}
                  </span>
                )}
                <Notification />
                <div className="nav__userDetails__container ms-2">
                  <Dropdown autoClose="outside">
                    <Dropdown.Toggle
                      id="user-details"
                      variant="transparent"
                      className="d-flex align-items-center"
                    >
                      <img
                        src={img ? img : default_user_circle}
                        height="30"
                        width="30"
                        className="user_profile__picture"
                        alt="user_profile_picture"
                        onError={onError}
                      />
                      <span className="px-3 text-white user__name">
                        {userRole?.firstName +
                          " " +
                          userRole?.middleName +
                          " " +
                          userRole?.lastName}{" "}
                        {selectedUser.value && selectedUser.label && (
                          <div
                            className="position-absolute top-50 mt-2 small text-truncate"
                            style={{ maxWidth: "100" }}
                          >
                            <OverlayTrigger
                              key="left"
                              placement="left"
                              overlay={
                                <Tooltip id="tooltip-left">
                                  {selectedUser.label.split(" ").pop()}
                                </Tooltip>
                              }
                            >
                              <span className="text-white user__name">
                                {selectedUser.label.split(" ").pop()}
                              </span>
                            </OverlayTrigger>
                          </div>
                        )}
                      </span>
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                      {userRole.role === "Super Admin" && (
                        <>
                          {!proxy && (
                            <>
                              <Dropdown.Item href="#">Admin View</Dropdown.Item>
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  onClick={() =>
                                    setShowAdminView(!showAdminView)
                                  }
                                />
                                <span className="slider round"></span>
                              </label>
                            </>
                          )}

                          {(showAdminView || proxyLocalStroage) && (
                            <Dropdown.Item
                              href="#"
                              onClick={() => setShow(true)}
                            >
                              Proxy{" "}
                              {selectedUser.value && selectedUser.label && (
                                <span className="text-pink">
                                  : {selectedUser.label.split("(").slice(0, 1)}
                                </span>
                              )}
                            </Dropdown.Item>
                          )}
                        </>
                      )}
                      {!proxy && (
                        <>
                          <Dropdown.Divider />
                          <Dropdown.Item
                            href="#"
                            onClick={() => {
                              authService.signoutRedirectCallback();
                            }}
                          >
                            Sign Out
                          </Dropdown.Item>
                        </>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </>
          )}
        </Container>
      </Navbar>
      <Modal
        show={show}
        onHide={closeModal}
        backdrop="static"
        keyboard={false}
        className="tms__modal"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Select Target User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="custom_form_control">
            <label htmlFor="" className="form-label">
              Please enter targeted user name
            </label>
            <DropdownSelect
              options={getUsersLists}
              handleChange={handleNewUser}
              placeholder={
                selectedUser.value && selectedUser.label
                  ? selectedUser.label
                  : "Select target user"
              }
              disabled={proxyData || proxyLocalStroage}
            />
          </div>
        </Modal.Body>
        {proxyData || proxyLocalStroage ? (
          <Modal.Footer className="justify-content-between">
            <button className="btn btn-rounded btn-danger" onClick={cancelUser}>
              Log out
            </button>
          </Modal.Footer>
        ) : (
          <Modal.Footer className="justify-content-between">
            <button
              className="btn btn-rounded btn-gradient-success"
              onClick={handleSave}
            >
              Save
            </button>
            <button
              className="btn btn-rounded btn-outline-warning"
              onClick={cancelUser}
            >
              {selectedUser.value && selectedUser.label ? "Clear" : "Cancel"}
            </button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
}
