/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeTrainingTopicAvailabilityRequest } from "../models/ChangeTrainingTopicAvailabilityRequest";
import type { TrainingTopicCollectionResponseDto } from "../models/TrainingTopicCollectionResponseDto";
import type { TrainingTopicData } from "../models/TrainingTopicData";
import type { TrainingTopicPaginationResponseDto } from "../models/TrainingTopicPaginationResponseDto";
import type { StoreTrainingTopicRequest } from "../models/StoreTrainingTopicRequest";
import type { UpdateTrainingTopicRequest } from "../models/UpdateTrainingTopicRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingTopicService {
  /**
   * Get list of training topic (Pagination)
   * Returns list of training topic with pagination
   * @param name Search By Name
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param orderBy Order By
   * @param order Order (By default asc)
   * @param trainingLevelId Training Level Id
   * @param trainingTypeId Training Type Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingTopicPaginationList(
    name?: string,
    page?: number,
    limit?: number,
    orderBy?: string,
    order?: string,
    trainingLevelId?: string,
    trainingTypeId?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicPaginationResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-topic`,
      query: {
        name: name,
        page: page,
        limit: limit,
        orderBy: orderBy,
        order: order,
        trainingLevelId: trainingLevelId,
        trainingTypeId: trainingTypeId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store training topic information
   * Store training topic & return training topic data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeTrainingTopic(
    requestBody?: StoreTrainingTopicRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicData;
  }> {
    return __request({
      method: "POST",
      path: `/api/training-topic`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get training topic information
   * Get training topic information
   * @param id Training topic id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingTopicById(id: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-topic/${id}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update training topic information
   * Update training topic & return training topic data
   * @param id Training Topic Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateTrainingTopicById(
    id: string,
    requestBody?: UpdateTrainingTopicRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicData;
  }> {
    return __request({
      method: "PATCH",
      path: `/api/training-topic/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of training topic
   * Get list training topic information in response of traning type
   * @param trainingTypeId Training Type Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingTopicList(
    trainingTypeId: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-topic/list`,
      query: {
        trainingTypeId: trainingTypeId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Enable Training Topic
   * Enable Training Topic
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static enableTrainingTopic(
    requestBody?: ChangeTrainingTopicAvailabilityRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-topic/enable`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Disable Training Topic
   * Disables Training Topic
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static disableTrainingTopic(
    requestBody?: ChangeTrainingTopicAvailabilityRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-topic/disable`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of all training topic
   * Returns list of training topic
   * @returns any successful operation
   * @throws ApiError
   */
  public static getAllTrainingTopicList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-topic/get-all`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get list of requested training topic
   * Returns list of requested training topic
   * @returns any successful operation
   * @throws ApiError
   */
  public static getRequestedTrainingTopicList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingTopicCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-topic/requested-topic`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
