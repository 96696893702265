import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

type AuthState = {
  isAuthenticated: boolean | null
  token: string | null
  user : any
}

const slice = createSlice({
  name: 'auth',
  initialState: { user: {}, isAuthenticated: null, token: null } as AuthState,
  reducers: {
    setCredentials: (
      state,
      { payload: { isAuthenticated, token, user } }: PayloadAction<{ isAuthenticated: boolean; token: string; user: object }>
    ) => {
      state.isAuthenticated = isAuthenticated
      state.token = token
      state.user = user
    },
  },
})

export const { setCredentials } = slice.actions

export default slice.reducer

export const selectIsAuthenticated = (state: RootState) => state.auth.isAuthenticated

export const selectToken = (state: RootState) => state.auth.token

export const selectUser = (state: RootState) => state?.auth?.user


