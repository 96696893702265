/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { UnitCollectionResponseData } from "../models/UnitCollectionResponseData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class UnitService {
  /**
   * Get list Of Units
   * Returns list of Units
   * @param departmentId Search By Department
   * @param sectionId Search By Section
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUnitList(
    departmentId?: string,
    sectionId?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UnitCollectionResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/unit`,
      query: {
        departmentId: departmentId,
        sectionId: sectionId,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
