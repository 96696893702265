/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AnnualTrainingBudgetData } from "../models/AnnualTrainingBudgetData";
import type { TrainingBudgetYearData } from "../models/TrainingBudgetYearData";
import type { StoreAnnualTrainingBudgetRequest } from "../models/StoreAnnualTrainingBudgetRequest";
import type { UpdateAnnualTrainingBudgetRequest } from "../models/UpdateAnnualTrainingBudgetRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class AnnualTrainingBudgetService {
  /**
   * Get annual training budget information
   * Returns annual training budget information
   * @param year Search By Year
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getAnnualTrainingBudgetInformation(
    year: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: AnnualTrainingBudgetData;
  }> {
    return __request({
      method: "GET",
      path: `/api/annual-training-budget`,
      query: {
        year: year,
      },
    });
  }

  /**
   * Store annual training budget
   * Store annual training budget & return training budget data
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeAnnualTrainingBudget(
    requestBody?: StoreAnnualTrainingBudgetRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: AnnualTrainingBudgetData;
  }> {
    return __request({
      method: "POST",
      path: `/api/annual-training-budget`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update annual training budget information
   * Update annual training budget information
   * @param trainingBudget Training budget id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateTrainingBudget(
    trainingBudget: string,
    requestBody?: UpdateAnnualTrainingBudgetRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: AnnualTrainingBudgetData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/annual-training-budget/${trainingBudget}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of year for which training budget is available
   * Returns list of yearr
   * @returns any successful operation
   * @throws ApiError
   */
  public static getAvailableBudgetYears(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingBudgetYearData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-budget-years`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
