/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChangeUserRoleAvailabilityRequest } from "../models/ChangeUserRoleAvailabilityRequest";
import type { UserRoleData } from "../models/UserRoleData";
import type { UserRolePaginationResponseData } from "../models/UserRolePaginationResponseData";
import type { StoreUserRoleRequest } from "../models/StoreUserRoleRequest";
import type { UpdateUserRoleRequest } from "../models/UpdateUserRoleRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class UserRoleService {
  /**
   * Get list of user roles
   * Returns list of user roles
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param orderBy Order By
   * @param order Order (By default asc)
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUserRoleList(
    page?: number,
    limit?: number,
    orderBy?: string,
    order?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRolePaginationResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/user-role`,
      query: {
        page: page,
        limit: limit,
        orderBy: orderBy,
        order: order,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Assign a role to the user
   * Assigns a roles to user
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static assignUserRole(
    requestBody?: StoreUserRoleRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRoleData;
  }> {
    return __request({
      method: "POST",
      path: `/api/user-role`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get user role information
   * Returns role information for the user
   * @param id User Role Id
   * @returns any Successful operation
   * @throws ApiError
   */
  public static getUserRole(id: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRoleData;
  }> {
    return __request({
      method: "GET",
      path: `/api/user-role/${id}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Update user role
   * Update user role information
   * @param id User Role Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateuserRole(
    id: string,
    requestBody?: UpdateUserRoleRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRoleData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/user-role/${id}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Enable User
   * Enables user
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static enableUser(
    requestBody?: ChangeUserRoleAvailabilityRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRoleData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/user-role/enable`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Disable User
   * Disables user
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static disableUser(
    requestBody?: ChangeUserRoleAvailabilityRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UserRoleData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/user-role/disable`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }
}
