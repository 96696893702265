/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { JobPositionCollectionResponseData } from "../models/JobPositionCollectionResponseData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class JobPositionService {
  /**
   * Get list of job position
   * Returns list of job Position
   * @returns any successful operation
   * @throws ApiError
   */
  public static getJobPositionList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: JobPositionCollectionResponseData;
  }> {
    return __request({
      method: "GET",
      path: `/api/job-position`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
