import { useEffect } from "react";
import AuthService from "../../services/authService";
import { useHistory } from 'react-router-dom'
import { useSelector } from "react-redux";
import { selectIsAuthenticated } from "./authSlice";

const Login = (props: any): any => {
    const history = useHistory();
    const isAuthenticated = useSelector(selectIsAuthenticated);
    const authService = new AuthService();
    useEffect(() => {
        if (isAuthenticated) {
            history.push('/home');
        }
    }, [isAuthenticated, history]);
    return isAuthenticated ? (
        <>
            You are logged in.
            <button onClick={() => {
                history.push("/home");
            }}>Go Home</button>
        </>
    ) : (
        <>
            <div>
                <div style={{ margin: '0', position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
                    <button
                        className="btn btn-gradient-success login-with-sso"
                        onClick={() => { authService.signinRedirect(); }}
                    >
                        Login with SSO
                    </button>
                </div>
            </div>
        </>
    );
}

export default Login;