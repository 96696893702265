import "../../../assets/css/footer.scss";
import footerLogo from "../../../assets/images/ncell-fLogo.png";

export default function Footer() {
  return (
    <footer className="footer__container">
      <div className="section__copyright text-center">
        &copy; Copyright Ncell. All rights reserved.
      </div>
      <div className="footer__logo">
        <img src={footerLogo} alt="footer_logo" />
      </div>
    </footer>
  );
}
