import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";
import { Footer, Header, Sidebar } from "./pages/navigation";
import { BrowserRouter as Router, useHistory } from "react-router-dom";
import { Routes } from "./routes/Routes";
import { useDispatch, useSelector } from "react-redux";
import { selectIsAuthenticated, setCredentials } from "./pages/auth/authSlice";
import AuthService from "./services/authService";
import { useEffect, useState } from "react";
import useTmsCoreApi from "./hooks/useTmsCoreApi";
import { MeService } from "./services/tms-core-services";
import { setUserRole } from "./pages/auth/userRoleSlice";
import Spinner from "./components/spinner";
import { proxyUserId, setProxyUser } from "./pages/auth/proxyUserSlice";

function App() {
  const dispatch = useDispatch();
  const authService = new AuthService();
  const history = useHistory();
  const { dismissError, error, isLoading, handleRequest } = useTmsCoreApi();

  const localstorageData = localStorage.getItem("proxyUserId"); // get proxy data from localStorage

  useEffect(() => {
    authService
      .getUser()
      .then((user) => {
        if (user) {
          dispatch(
            setCredentials({
              isAuthenticated: authService.isAuthenticated(),
              token: authService.getToken(),
              user,
            })
          );
          const getAuthUserDataPromise = handleRequest(
            MeService.getAuthUserData()
          );
          const getAuthUserPermissionPromise = handleRequest(
            MeService.getAuthUserPermission()
          );

          const getProxyUserDetailsPromise = handleRequest(
            MeService.getUserDetailById()
          );

          Promise.all([
            getAuthUserDataPromise,
            getAuthUserPermissionPromise,
            getProxyUserDetailsPromise,
          ])
            .then((values: any) => {
              dispatch(
                setUserRole({
                  userRole: values[0].data,
                  permission: values[1].data.permission,
                })
              );
              if (localstorageData) {
                dispatch(
                  setProxyUser({
                    userId: localstorageData.toString(),
                    isProxy: true,
                    proxyDetails: values[2].data,
                  })
                );
              }
            })
            .catch((exception) => {
              authService.signoutRedirectCallback();
              console.log(exception);
            });
        }
      })
      .catch((err) => {
        if (!err) authService.signoutRedirectCallback();
        console.log(err);
      });
  }, []);

  const isAuthenticated = useSelector(selectIsAuthenticated);

  // set Last Loggin Time
  useEffect(() => {
    if (isAuthenticated && !localStorage.getItem("loggedTime")) {
      handleRequest(MeService.storeLoggedInTime())
        .then((data: any) => {
          const lastloggedin = data.data.lastLoggedInTime;
          localStorage.setItem("loggedTime", lastloggedin);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [isAuthenticated]);

  return (
    <Router>
      {isLoading ? (
        <div>
          <Spinner />
        </div>
      ) : (
        <>
          <Header />
          {isAuthenticated && <Sidebar />}
          <div className="main__panel">
            <Routes></Routes>
          </div>
          {isAuthenticated && <Footer />}
        </>
      )}
    </Router>
  );
}

export default App;
