import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import type { RootState } from '../../store/store'

type proxyUser = {
    userId: string,
    isProxy: boolean,
    proxyDetails: {
        firstName: string,
        middleName: string,
        lastName: string,
        phoneNumber: string,
        email: string,
        username: string,
        role: string,
    },
}

const proxy = createSlice({
    name: 'proxyUser',
    initialState: {
        userId: '',
        isProxy: false,
        proxyDetails: {
            firstName: "",
            middleName: "",
            lastName: "",
            phoneNumber: "",
            email: "",
            username: "",
            role: ""
        }
    } as proxyUser,
    reducers: {
        setProxyUser: (
            state,
            { payload:
                { userId, isProxy, proxyDetails }
            }: PayloadAction<{
                userId: string,
                isProxy: boolean,
                proxyDetails: {
                    firstName: string,
                    middleName: string,
                    lastName: string,
                    phoneNumber: string,
                    email: string,
                    username: string,
                    role: string,
                }
            }>
        ) => {
            state.userId = userId
            state.isProxy = isProxy
            state.proxyDetails.firstName = proxyDetails.firstName
            state.proxyDetails.middleName = proxyDetails.middleName
            state.proxyDetails.lastName = proxyDetails.lastName
            state.proxyDetails.phoneNumber = proxyDetails.phoneNumber
            state.proxyDetails.email = proxyDetails.email
            state.proxyDetails.username = proxyDetails.username
            state.proxyDetails.role = proxyDetails.role
        },
    },
});


export const { setProxyUser } = proxy.actions;

export default proxy.reducer

export const proxyUserId = (state: RootState) => state?.proxyUser?.userId;

export const isProxy = (state: RootState) => state?.proxyUser?.isProxy;

export const proxyDetails = (state: RootState) => state?.proxyUser?.proxyDetails;