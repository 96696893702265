import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import type { RootState } from "../../store/store";

type feedbacks = {
  search: string;
  from: Date | null;
  to: Date | null;
  statusId: string | null;
  statusValue: string | null;
};

const feedback = createSlice({
  name: "feedbackReportParams",
  initialState: {
    search: "",
    from: null,
    to: null,
    statusId: "",
    statusValue: "",
  } as feedbacks,
  reducers: {
    setFeedbackReport: (
      state,
      {
        payload: { search, from, to, statusId, statusValue },
      }: PayloadAction<{
        search: string;
        from: Date | null;
        to: Date | null;
        statusId: string;
        statusValue: string;
      }>
    ) => {
      state.search = search;
      state.from = from;
      state.to = to;
      state.statusId = statusId;
      state.statusValue = statusValue;
    },
  },
});

export const { setFeedbackReport } = feedback.actions;

export default feedback.reducer;

export const feedbackReport = (state: RootState) => state?.feedback;
