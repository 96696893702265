/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { NotificationData } from "../models/NotificationData";
import type { NotificationPaginationData } from "../models/NotificationPaginationData";
import type { UnreadNotificationCountData } from "../models/UnreadNotificationCountData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class NotificationService {
  /**
   * Get notifications for authenticated user
   * Returns notifications for authenticated user
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @returns any successful operation
   * @throws ApiError
   */
  public static getNotificationList(
    page?: number,
    limit?: number
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: NotificationPaginationData;
  }> {
    return __request({
      method: "GET",
      path: `/api/notification`,
      query: {
        page: page,
        limit: limit,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Mark notification as read
   * Marks notification as read
   * @param notification Notification Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static markNotificationAsRead(
    notification: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: NotificationData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/notification/${notification}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get unread notification count for authenticated user
   * Returns unread notification count for authenticated user
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUnreadNotificationCount(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UnreadNotificationCountData;
  }> {
    return __request({
      method: "GET",
      path: `/api/notification/unread-notification-count`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
