/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { TrainingLevelCollectionResponseDto } from "../models/TrainingLevelCollectionResponseDto";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingLevelService {
  /**
   * Get list of training level
   * Returns list of training level
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingLevelList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingLevelCollectionResponseDto;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-level`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
