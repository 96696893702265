/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CurrentYearData } from "../models/CurrentYearData";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class CurrentYearService {
  /**
   * Get current year for creating/updating annual budget & training request
   * Returns current year
   * @returns any successful operation
   * @throws ApiError
   */
  public static getCurrentYear(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: CurrentYearData;
  }> {
    return __request({
      method: "GET",
      path: `/api/current-year`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
