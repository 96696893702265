import { lazy } from "react";

const routes = [
  {
    path: "home",
    component: lazy(() => import("../pages/dashboard")),
    exact: true,
    permissionName: "view_home",
  },
  {
    path: "topics",
    component: lazy(() => import("../pages/topics")),
    exact: true,
    permissionName: "view_topics",
  },
  {
    path: "create-training-topic",
    component: lazy(() => import("../pages/topics/CreateTrainingTopic")),
    exact: true,
    permissionName: "create_training_topic",
  },
  {
    path: "update-training-topic/:id",
    component: lazy(() => import("../pages/topics/CreateTrainingTopic")),
    exact: true,
    permissionName: "update_training_topic",
  },
  {
    path: "attendees/:id",
    component: lazy(() => import("../pages/attendeeList")),
    exact: true,
    permissionName: "mark_attended_unattended",
  },
  {
    path: "participants/:id",
    component: lazy(() => import("../pages/participantList")),
    exact: true,
    permissionName: "view_subordinate_training_event",
  },
  {
    path: "tnas",
    component: lazy(() => import("../pages/tnas/")),
    exact: true,
    permissionName: "my_tna",
  },
  {
    path: "create-training-request",
    component: lazy(() => import("../pages/tnas/CreateTrainingRequest")),
    exact: true,
    permissionName: "create_training_request",
  },
  {
    path: "update-training-request/:id",
    component: lazy(() => import("../pages/tnas/CreateTrainingRequest")),
    exact: true,
    permissionName: "update_training_request",
  },
  {
    path: "events",
    component: lazy(() => import("../pages/events")),
    exact: true,
    permissionName: "view_events",
  },
  {
    path: "feedback",
    component: lazy(() => import("../pages/feedback")),
    exact: true,
    permissionName: "view_feedback",
  },
  {
    path: "feedback-form/:id",
    component: lazy(() => import("../pages/feedback/FeedbackForm")),
    exact: true,
    permissionName: "view_feedback_form",
    // permissionName: "view_individual_feedback",
  },
  {
    path: "training-event-feedback-details/:id",
    component: lazy(() =>
      import("../pages/feedback/TrainingEventFeedbackDetails")
    ),
    exact: true,
    permissionName: "view_feedback",
    // permissionName: "view_feedback_result",
  },
  {
    path: "budgets",
    component: lazy(() => import("../pages/budget")),
    exact: true,
    permissionName: "view_budget_allocation_report",
  },
  {
    path: "create-training-events",
    component: lazy(() => import("../pages/events/createTrainingEvents")),
    exact: true,
    permissionName: "create_training_event",
  },
  {
    path: "annual-training-budget",
    component: lazy(() => import("../pages/budget/CreateAnnualTrainingBudget")),
    exact: true,
    permissionName: "create_annual_training_budget",
  },
  {
    path: "reports",
    component: lazy(() => import("../pages/reports/")),
    exact: true,
    permissionName: "view_reports",
  },
  {
    path: "training-base-report",
    component: lazy(() => import("../pages/reports/TrainingBaseReport")),
    exact: true,
    permissionName: "view_reports",
  },
  {
    path: "budget-allocation-report",
    component: lazy(() => import("../pages/reports/BudgetAllocationReport")),
    exact: true,
    permissionName: "view_reports",
  },
  {
    path: "tna-vs-training-report",
    component: lazy(() => import("../pages/reports/TnaVsTrainingConducted")),
    exact: true,
    permissionName: "view_reports",
  },
  {
    path: "employee-base-report",
    component: lazy(() => import("../pages/reports/EmployeeBaseReport")),
    exact: true,
  },
  {
    path: "report/feedback",
    component: lazy(() => import("../pages/feedback")),
    exact: true,
    permissionName: "view_feedback",
  },
  {
    path: "download-report",
    component: lazy(() => import("../pages/reports/DownloadReport")),
    exact: true,
    permissionName: "view_reports",
  },
  {
    path: "users",
    component: lazy(() => import("../pages/users")),
    exact: true,
    permissionName: "view_users",
  },
  {
    path: "suggestion",
    component: lazy(() => import("../pages/suggestion/Suggestion")),
    exact: true,
    permissionName: "view_suggestion",
  },
  {
    path: "elearning",
    component: lazy(() => import("../pages/eLearning/Elearning")),
    exact: true,
    permissionName: "view_e_learning_file",
  },
  {
    path: "upload-eLearning-file",
    component: lazy(() => import("../pages/eLearning/UploadElearningFile")),
    exact: true,
    permissionName: "view_e_learning_file",
  },
  {
    path: "uploaded-eLearning-list",
    component: lazy(() => import("../pages/eLearning/UploadedElearningList")),
    exact: true,
    permissionName: "view_e_learning_file",
  },
  {
    path: "uploaded-eLearning-report",
    component: lazy(() => import("../pages/eLearning/UploadedElearningReport")),
    exact: true,
    permissionName: "view_e_learning_file",
  },
];

export default routes;
