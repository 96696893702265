/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SuggestionData } from "../models/SuggestionData";
import type { SendSuggestionRequest } from "../models/SendSuggestionRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class SuggestionService {
  /**
   * Send suggestion to super admin
   * Send suggestion
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static sendSuggestion(
    requestBody?: SendSuggestionRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: SuggestionData;
  }> {
    return __request({
      method: "POST",
      path: `/api/send-suggestion`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
