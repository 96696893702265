import { Suspense, useState } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import routes from './protected-route-items';

const ProtectedRoutes = (props) => {
    const { permissionLists } = props
    return <Switch>
        <Suspense
            fallback={() => (<>LOADING</>)}
        >
            <Route
                path="/"
                exact
                render={
                    ({ location }) => {
                        return (
                            <Redirect
                                to={{
                                    pathname: '/home',
                                    state: { from: location }
                                }}
                            />
                        )
                    }
                }
            />

            {routes.map(({ component: Component, path, exact, permissionName }) => {
                let isAuthorized = false;
                if (permissionName) {
                    const permission = Array.isArray(permissionLists) && permissionLists.find(x => x.permissionName === permissionName);
                    if (permission) isAuthorized = true;
                }
                return isAuthorized &&
                    <Route
                        path={`/${path}`}
                        key={path}
                        exact={exact}
                    >
                        <Component />
                    </Route>
                // :
                // <Redirect
                //     to={{
                //         pathname: '/home',
                //     }}
                // />
            })
            }

        </Suspense>
    </Switch >
}

export default ProtectedRoutes;