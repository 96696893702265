/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApproveTrainingEventRequest } from "../models/ApproveTrainingEventRequest";
import type { ParticipantAttendanceCollectionData } from "../models/ParticipantAttendanceCollectionData";
import type { TrainingEventAttendeesPaginationData } from "../models/TrainingEventAttendeesPaginationData";
import type { TrainingEventData } from "../models/TrainingEventData";
import type { TrainingEventPaginationData } from "../models/TrainingEventPaginationData";
import type { TrainingEventParticipantCollectionData } from "../models/TrainingEventParticipantCollectionData";
import type { TrainingEventParticipantData } from "../models/TrainingEventParticipantData";
import type { TrainingEventSearchedUserData } from "../models/TrainingEventSearchedUserData";
import type { TrainingEventStatusCollectionData } from "../models/TrainingEventStatusCollectionData";
import type { TrainingHostTypeCollectionData } from "../models/TrainingHostTypeCollectionData";
import type { TrainingModeTypeCollectionData } from "../models/TrainingModeTypeCollectionData";
import type { UploadImageData } from "../models/UploadImageData";
import type { DisapproveTrainingEventRequest } from "../models/DisapproveTrainingEventRequest";
import type { MarkAttendeesRequest } from "../models/MarkAttendeesRequest";
import type { PostponeTrainingEventRequest } from "../models/PostponeTrainingEventRequest";
import type { StoreTrainingEventRequest } from "../models/StoreTrainingEventRequest";
import type { UpdateTrainingCostRequest } from "../models/UpdateTrainingCostRequest";
import type { UploadImageRequest } from "../models/UploadImageRequest";
import type { CancelablePromise } from "../core/CancelablePromise";
import { request as __request } from "../core/request";

export class TrainingEventService {
  /**
   * Get list of training event mode
   * Returns list of training event mode type
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEventModeList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingModeTypeCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event-mode`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get list of training event participant status
   * Returns list of training event participant status
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEventParticipantStatusList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventStatusCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event-participants-status`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get list of training event status
   * Returns list of training event status
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEventStatusList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventStatusCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event-status`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get list of training event host type
   * Returns list of training event host type
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingHostTypeList(): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingHostTypeCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-host-type`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Filter training event
   * Returns filtered training events
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @param eventStatusId Training Event Status Id
   * @param topicName Topic Name
   * @param fromDate Starting Date
   * @param toDate End Date
   * @returns any successful operation
   * @throws ApiError
   */
  public static viewTrainingEvents(
    page?: number,
    limit?: number,
    eventStatusId?: string,
    topicName?: string,
    fromDate?: string,
    toDate?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventPaginationData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event`,
      query: {
        page: page,
        limit: limit,
        eventStatusId: eventStatusId,
        topicName: topicName,
        fromDate: fromDate,
        toDate: toDate,
      },
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Store training event
   * Create a new training event
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static storeTrainingEvent(
    requestBody?: StoreTrainingEventRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "POST",
      path: `/api/training-event`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Get training event details
   * Returns detail of training event
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEvent(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event/${event}`,
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Approve training event for the participant
   * Approves training event for the participant
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static approveTrainingEvent(
    event: string,
    requestBody?: ApproveTrainingEventRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventParticipantData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/approve/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Reject training event for the participant
   * Rejects training event for the participant
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static rejectTrainingEvent(
    event: string,
    requestBody?: DisapproveTrainingEventRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventParticipantData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/reject/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Cancel Training Event
   * Cancels training event
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static cancelTrainingEvent(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/cancel/${event}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Postpone Training Event
   * Postpones training event
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static postponeTrainingEvent(
    event: string,
    requestBody?: PostponeTrainingEventRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/postpone/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Complete Training Event (Participants)
   * Participants can mark training event as complete
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static completeTrainingEvent(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventParticipantData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/complete-training/${event}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Complete Training Event (Admin)
   * Admin can mark training event as complete
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static completeEvent(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/complete-event/${event}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get User From Unit
   * User From Unit
   * @param trainingTopicId Training Topic Id
   * @param startDate Date From
   * @param employee
   * @param departments
   * @param units
   * @param tnaRequest TNA Requested
   * @param hostId Host Id
   * @param endDate Date To
   * @returns any successful operation
   * @throws ApiError
   */
  public static getUserFromUnit(
    trainingTopicId: string,
    startDate: string,
    employee?: string,
    departments?: string,
    units?: string,
    tnaRequest?: boolean,
    hostId?: string,
    endDate?: string
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventSearchedUserData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event/search-user`,
      query: {
        employee: employee,
        departments: departments,
        units: units,
        trainingTopicId: trainingTopicId,
        tnaRequest: tnaRequest,
        hostId: hostId,
        startDate: startDate,
        endDate: endDate,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of attendees for the event
   * Returns of attendees for the event
   * @param event Training Event Id
   * @param page Page (By default 1)
   * @param limit Limit (By default 10)
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEventAttendees(
    event: string,
    page?: number,
    limit?: number
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventAttendeesPaginationData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event/attendees/${event}`,
      query: {
        page: page,
        limit: limit,
      },
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Mark attendees as attended
   * Marks attendees as attended
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static markAttended(
    event: string,
    requestBody?: MarkAttendeesRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: ParticipantAttendanceCollectionData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/mark-attended/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Mark attendees as unattended
   * Marks attendees as unattended
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static markUnattended(
    event: string,
    requestBody?: MarkAttendeesRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: ParticipantAttendanceCollectionData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/mark-unattended/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Marks Training Event As In-Progress
   * Marks Training Event As In-Progress
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static makeTrainingEventInProgress(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/in-progress/${event}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Update Training Event Cost
   * Update Training Event Cost
   * @param event Training Event Id
   * @param requestBody
   * @returns any successful operation
   * @throws ApiError
   */
  public static updateTrainingCost(
    event: string,
    requestBody?: UpdateTrainingCostRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventData;
  }> {
    return __request({
      method: "PUT",
      path: `/api/training-event/cost/${event}`,
      body: requestBody,
      mediaType: "application/json",
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Error in input`,
      },
    });
  }

  /**
   * Get list of subordinates for the event
   * Returns list of subordinates for which user can perform approve/reject action
   * @param event Training Event Id
   * @returns any successful operation
   * @throws ApiError
   */
  public static getTrainingEventSubordinates(event: string): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: TrainingEventParticipantCollectionData;
  }> {
    return __request({
      method: "GET",
      path: `/api/training-event/subordinates/${event}`,
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }

  /**
   * Upload Training Event Image
   * Upload Image for the training event
   * @param formData
   * @returns any successful operation
   * @throws ApiError
   */
  public static uploadEventImage(
    formData?: UploadImageRequest
  ): CancelablePromise<{
    resCod?: string;
    resDesc?: string;
    data?: UploadImageData;
  }> {
    return __request({
      method: "POST",
      path: `/api/training-event/upload`,
      formData: formData,
      mediaType: "multipart/form-data",
      errors: {
        400: `Error in input`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
}
